import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { MapToComponents } from 'react-map-to-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';
import PortableText from '../components/PortableText';
import Logo from '../assets/images/logo-earlybirds.inline.svg';
import { mq } from '../styles/breakpoints';
import ThreeColumns from '../components/sections/ThreeColumns';
import TwoColumns from '../components/sections/TwoColumns';
import SingleColumn from '../components/sections/SingleColumn';
import Testimony from '../components/sections/Testimony';
import Hero from '../components/sections/Hero';
import BlockQuestions from '../components/sections/BlockQuestions';
import More from '../components/sections/More';
import Table from '../components/sections/Table';
import TwoColumnsQuery from '../fragments/TwoColumns';
import ThreeColumnsQuery from '../fragments/ThreeColumns';
import SingleColumnQuery from '../fragments/SingleColumn';
import HeroQuery from '../fragments/Hero';
import BlockQuestionsQuery from '../fragments/BlockQuestions';
import MoreQuery from '../fragments/More';
import TableauQuery from '../fragments/Tableau';

const TestimoniesListStyles = styled.div`
  padding-bottom: var(--padding);
  padding-top: var(--margin);
  position: relative;

  .section-title {
    margin-bottom: var(--padding);
    text-align: center;
  }

  .testimony {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--padding);

    ${mq[1]} {
      flex-direction: row;
    }

    .title-middle {
      margin: 0 0 0.5rem;
    }
    .author {
      margin: 0.5rem 0;
    }

    .gatsby-image-wrapper {
      border-radius: 100%;
      margin-bottom: 0;
      width: 9.375rem;

      img {
        border-radius: 100%;
      }
    }

    svg {
      width: 9.375rem;
    }

    .testimony-description {
      align-self: center;
      padding-left: var(--margin);
      max-width: 40.5rem;
      margin-top: var(--margin);

      ${mq[1]} {
        margin-top: 0px;
        max-width: none;
      }

      *:last-child {
        margin-bottom: 0;
      }
    }

    .block {
      *:first-child {
        margin-top: 0;
      }
    }
  }
  .testimony-link {
    color: var(--blue-dark);
    text-decoration: underline;
  }
`;

export default function Temoignages({ data, location, pageContext }) {
  const testimonies = data.testimonies.nodes;

  const { title, displayTitle, content, titleSeo, descriptionSeo, image } =
    data?.TestimoniesPage;

  return (
    <>
      <SEO
        title={titleSeo || title}
        description={descriptionSeo}
        location={location}
      />
      <TestimoniesListStyles>
        {testimonies && (
          <div className="container">
            {image?.asset && (
              <GatsbyImage
                image={getImage(image.asset)}
                alt={image.altText || title}
                className="image-header"
              />
            )}
            {displayTitle && <h1 className="section-title">{title}</h1>}
            <MapToComponents
              getKey={(section) => section.id || section._key}
              getType={(section) => section._type}
              list={content}
              map={{
                threeColumns: ThreeColumns,
                twoColumns: TwoColumns,
                singleColumn: SingleColumn,
                hero: Hero,
                testimonySection: Testimony,
                blockQuestions: BlockQuestions,
                more: More,
                tableau: Table,
              }}
              mapDataToProps={{
                threeColumns: ({ data }) => ({
                  title: data.title,
                  bgTitle: data.bgTitle?.value,
                  bgSection: data.bgSection?.value,
                  showTitle: data.showTitle,
                  cards: data.cards,
                  titleOptions: data.titleOptions,
                }),
                twoColumns: ({ data }) => ({
                  alignContent: data.alignContent,
                  title: data.title,
                  showTitle: data.showTitle,
                  bgColor: data.bgColor?.value,
                  cards: data.cards,
                  colsProportions: data.colsProportions,
                  ctaButton: data.ctaButton,
                  titleOptions: data.titleOptions,
                }),
                singleColumn: ({ data }) => ({
                  background: data.background?.value,
                  alignCenter: data.alignCenter,
                  ctaButton: data.ctaButton,
                  description: data.columnDescription?._rawText,
                  title: data.title,
                  showTitle: data.showTitle,
                  titleOptions: data.titleOptions,
                }),
                hero: ({ data }) => ({
                  pageTitle: title,
                  images: data.images,
                }),
                steps: ({ data }) => ({
                  title: data.title,
                  steps: data.steps,
                }),
                testimonySection: ({ data }) => ({
                  testimonies: data.testimonyRef,
                  cta: data.cta,
                  ctaBg: data.ctaBg?.value,
                  ctaBgHover: data.ctaBghover?.value,
                  ctaColor: data.ctaColor?.value,
                  ctaColorHover: data.ctaColorHover?.value,
                }),
                blockQuestions: ({ data }) => ({
                  background: data.background?.value,
                  questions: data.questionsList,
                  showTitle: data.showTitle,
                  title: data.title,
                  titleOptions: data.titleOptions,
                }),
                more: ({ data }) => ({
                  text: data.moreText,
                  ctaButton: data.ctaButton,
                }),
                tableau: ({ data }) => ({
                  rows: data._rawDataTable?.rows,
                }),
              }}
            />

            {testimonies.map((testimony) => (
              <div key={`key-${testimony.id}`} className="testimony">
                {testimony.image && (
                  <Link to={`/temoignages/${testimony.slug.current}`}>
                    <GatsbyImage
                      image={getImage(testimony.image.asset)}
                      alt={testimony.place}
                      className="image"
                    />
                  </Link>
                )}
                {!testimony.image && (
                  <Link to={`/temoignages/${testimony.slug.current}`}>
                    <Logo />
                  </Link>
                )}
                <div className="testimony-description">
                  {testimony.title && (
                    <h2 className="title-middle">
                      <Link to={`/temoignages/${testimony.slug.current}`}>
                        {testimony.title}
                      </Link>
                    </h2>
                  )}
                  {testimony.testimonyExcerpt && (
                    <PortableText
                      blocks={testimony.testimonyExcerpt._rawText}
                    />
                  )}
                  <p className="author">{testimony.author}</p>
                  <Link
                    to={`/temoignages/${testimony.slug.current}`}
                    className="testimony-link"
                  >
                    Lire la suite
                  </Link>
                </div>
              </div>
            ))}
            {data.testimonies.totalCount > 12 && (
              <Pagination
                pageSize={parseInt(process.env.GATSBY_PAGE_SIZE)}
                totalCount={data.testimonies.totalCount}
                currentPage={pageContext.currentPage || 1}
                base="/temoignages"
              />
            )}
          </div>
        )}
      </TestimoniesListStyles>
    </>
  );
}

export const query = graphql`
  query TemoignagesQuery($skip: Int = 0, $pageSize: Int = 12) {
    TestimoniesPage: sanityPage(slug: { current: { eq: "temoignages" } }) {
      title
      displayTitle
      titleMenu
      titleSeo
      descriptionSeo
      image {
        asset {
          altText
          gatsbyImageData(width: 900)
        }
      }
      content {
        ...SingleColumnQuery
        ...TwoColumnsQuery
        ...ThreeColumnsQuery
        ...HeroQuery
        ...MoreQuery
        ...BlockQuestionsQuery
        ...TableauQuery
      }
    }
    testimonies: allSanityTestimony(
      limit: $pageSize
      skip: $skip
      filter: { slug: { current: { ne: null } } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      totalCount
      nodes {
        id
        title
        testimonyExcerpt {
          _rawText(resolveReferences: { maxDepth: 10 })
        }
        testimonyText {
          _rawText(resolveReferences: { maxDepth: 10 })
        }
        image {
          asset {
            gatsbyImageData(width: 150, height: 150)
          }
        }
        slug {
          current
        }
        author
        place
        publishedAt
      }
    }
  }
`;
